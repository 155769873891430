import type { ActionFunctionArgs, LoaderFunctionArgs } from "@remix-run/node";
import { json } from "@remix-run/node";
import { Form, useLoaderData, useNavigation } from "@remix-run/react";
import { authenticator } from "~/util/auth.server";
import { commitSession, getSession } from "~/util/auth-session.server";
import { CUSTOMER_PLATFORM_PATH } from "~/common/constants";
import Turnstile, { useTurnstile } from "react-turnstile";
import { useEffect, useState } from "react";
import {
  getEscapedFormData,
  redirectWithNotification,
  validateTurnstile,
} from "~/common/functions";

export const loader = async ({ request }: LoaderFunctionArgs) => {
  // 1. Lade Cloudflare Site Key
  const cloudflareSiteKey = process.env.CLOUDFLARE_SITE_KEY;
  if (!cloudflareSiteKey) {
    throw new Error("cloudflareSiteKey ist nicht gesetzt");
  }

  // 2. Authentifizierung prüfen
  await authenticator.isAuthenticated(request, {
    successRedirect: CUSTOMER_PLATFORM_PATH,
  });

  // 3. Session-Daten laden
  const cookie = await getSession(request.headers.get("cookie"));
  const authEmail = cookie.get("auth:email");
  const authError = cookie.get(authenticator.sessionErrorKey);

  // 4. Session-Änderungen committen
  return json({ cloudflareSiteKey, authEmail, authError } as const, {
    headers: {
      "set-cookie": await commitSession(cookie),
    },
  });
};

export async function action({ request }: ActionFunctionArgs) {
  const url = new URL(request.url);
  const currentPath = url.pathname;

  const clonedRequest = request.clone();

  const turnstileResponse = await getEscapedFormData(
    await request.formData(),
    "cf-turnstile-response",
    false
  );

  if (!turnstileResponse) {
    throw redirectWithNotification("Login: turnstile response is missing");
  }

  const isTurnstileValid = await validateTurnstile(turnstileResponse);
  if (!isTurnstileValid) {
    throw redirectWithNotification("Turnstile validation failed");
  }

  await authenticator.authenticate("TOTP", clonedRequest, {
    // The `successRedirect` route will be used to verify the OTP code.
    // This could be the current pathname or any other route that renders the verification form.
    successRedirect: "/verify",

    // The `failureRedirect` route will be used to render any possible error.
    // If not provided, ErrorBoundary will be rendered instead.
    failureRedirect: currentPath,
  });
}

export default function Login() {
  const { authEmail, authError } = useLoaderData<typeof loader>();
  const navigation = useNavigation();
  const text =
    navigation.state === "submitting" || navigation.state === "loading"
      ? "Bitte warten..."
      : "Einloggen";

  const { cloudflareSiteKey } = useLoaderData<typeof loader>();
  const [hasMounted, setHasMounted] = useState(false);
  const turnstile = useTurnstile();

  useEffect(() => {
    if (!cloudflareSiteKey) return;
    setHasMounted(true);
  }, [cloudflareSiteKey]);

  return (
    <div className="mx-auto flex h-screen w-screen max-w-7xl flex-col px-6">
      <div className="mx-auto flex h-full w-full max-w-[400px] flex-col items-center justify-center gap-6">
        {/* Email Form */}
        <div className="flex w-full flex-col items-center gap-6">
          <div className="flex w-full flex-col items-center justify-center gap-2">
            <div className="flex flex-col items-center gap-1">
              <h1 className="text-center text-2xl font-semibold tracking-tight">
                Kundenplattform
              </h1>
              <p className="text-center text-base font-normal text-gray-600">
                Gib die E-Mail-Adresse ein, die du beim Bezahlvorgang verwendet
                hast, um dich einzuloggen.
              </p>
            </div>
          </div>

          <Form
            method="POST"
            autoComplete="off"
            className="flex w-full flex-col gap-2"
            key={navigation.state}
          >
            <div className="flex flex-col">
              <label htmlFor="email" className="sr-only">
                Email
              </label>
              <input
                type="email"
                name="email"
                defaultValue={authEmail ? authEmail : ""}
                placeholder="max.mustermann@beispiel.de"
                className="h-11 rounded-md border-2 border-gray-200 bg-transparent px-4 text-base font-semibold placeholder:font-normal placeholder:text-gray-400"
                required
                disabled={
                  navigation.state === "submitting" ||
                  navigation.state === "loading"
                }
              />
            </div>
            {/* Errors Handling. */}
            {!authEmail && authError ? (
              <span className="font-semibold text-red-400 ml-3">
                {authError.message}
              </span>
            ) : (
              <span>&nbsp;</span>
            )}
            <button
              type="submit"
              className="clickable flex h-10 items-center justify-center rounded-md bg-gray-800"
              disabled={
                navigation.state === "submitting" ||
                navigation.state === "loading"
              }
            >
              <span className="text-sm font-semibold text-white">{text}</span>
            </button>
            <div
              className="cf-turnstile"
              data-sitekey={cloudflareSiteKey!}
            ></div>
            {hasMounted && (
              <Turnstile
                className="mt-6 mx-auto"
                sitekey={cloudflareSiteKey!}
                execution="render"
                retry="never"
              />
            )}
          </Form>
        </div>
      </div>

    </div>
  );
}
